import React from "react";

const SmallLogo = () => (
  <svg className="logo__sm" width="235" height="180" viewBox="0 0 235 180" fill="none" strokeWidth="5" strokeLinejoin="round" stroke="#000" xmlns="http://www.w3.org/2000/svg">
    <path d="M53 3v50H3z" fill="#b4e9c7" />
    <path d="M53 3v87l42-45z" fill="#5cd084" />
    <path d="M53 90l76-80 51 50-75 80z" fill="#b4e9c7" />
    <path d="M129 10l51 50 50-50zM105 140l35-37v74z" fill="#5cd084" />
    <path d="M128 60l15 15-15 15M120 55l-7 40M105 60L90 75l15 15" />
  </svg>
);

export default SmallLogo;