//theme colors and tints (dark900 to normal500 to light100)
const colors = {
    blue900: "#192c4c",
    blue800: "#2a4a7f",
    blue700: "#3a67b2",
    blue600: "#4b84e5",
    blue500: "#5393fe",
    blue400: "#73abff",
    blue300: "#cbdfff",
    blue200: "#e3efff",
    blue100: "#f1f8ff",

    green600: "#2E9752",
    green500: "#49C676",
    green200: "#78D69A",
    green100: "#B4E9C7",

    grey900: "#111010",
    grey800: "#3D3F42",
    grey700: "#7D7F81",
    grey600: "#A9AAAB",
    grey500: "#BEBFC0",
    grey400: "#D4D4D5",
    grey300: "#E9EAEA",
    grey200: "#F2F2F2",
    grey100: "#F9F9FA",

    orange600: "#FF8352",
    orange500: "#F46B3F",
    orange200: "#FDE1D9",
    orange100: "#FEF0EC",

    pink600: "#FA686A",
    pink500: "#FB888A",
    pink400: "#FC9C9E",
    pink300: "#FDB0B1",
    pink200: "#FDC4C5",
    pink100: "#FED7D8",

    white600: "#DBDBDB",
    white500: "#E2E2E2", 
    white400: "#E9E9E9", 
    white300: "#F0F0F0",
    white200: "#F7F7F7", 
    white100: "#FFFFFF",
}

export default colors;