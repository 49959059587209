import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import LargeLogo from "components/_ui/logos/Logo-lg";

const HeaderContainer = styled("div")`
    padding-top: 1em;

    @media(min-width: ${dimensions.maxwidthTablet}px) {
        padding-top: 3em;
        padding-bottom: 3em;
    }
`

const HeaderContent = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo-link {
        text-align: center;
    }

    @media(min-width: ${dimensions.maxwidthTablet}px) {
        flex-direction: row;
        justify-content: space-between;

        .logo-link {
            text-align: left;
        }
    }
`

const HeaderLinks = styled("div")`
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    border-top: 2px solid ${colors.grey900};
    border-bottom: 2px solid ${colors.grey900};

    a:not(:last-of-type) {
        margin-right: 3em;
    }

    @media(min-width: ${dimensions.maxwidthTablet}px) {
        justify-content: flex-end;
        max-width: 300px;
        border: 0;

        a:not(:last-of-type) {
            margin-right: 1.5em;
        }
    }

    @media(min-width: ${dimensions.maxwidthDesktop}px) {
        a:not(:last-of-type) {
            margin-right: 3em;
        }
    }

    a {
        color: currentColor;
        text-decoration: none;
        font-weight: 600;
        font-size: 1.25em;
        display: block;
        position: relative;

        &:hover {
            color: ${colors.orange500};
        }

        &.Link--is-active {
            color: ${colors.orange600};
        }
    }
`

const Header = () => (
    <HeaderContainer>
        <HeaderContent>
            <Link className="logo-link" to="/">
                <LargeLogo />
            </Link>
            <HeaderLinks>
                <Link
                    activeClassName="Link--is-active"
                    to="/work">
                    Work
                </Link>
                <Link
                    activeClassName="Link--is-active"
                    to="/resume">
                    Resume
                </Link>
                <Link
                    activeClassName="Link--is-active"
                    to="/blog">
                    Blog
                </Link>
            </HeaderLinks>
        </HeaderContent>
    </HeaderContainer>
)

export default Header;