import React from "react";

const LargeLogo = () => (
  <svg className="logo__lg" width="365" height="65" viewBox="0 0 1000 180" fill="none" strokeWidth="5" strokeLinejoin="round" stroke="#000" xmlns="http://www.w3.org/2000/svg">
    <path d="M53 3v50H3z" className="logo__lg--light" fill="#B4E9C7" />
    <path d="M53 3v87l42-45z" className="logo__lg--dark" fill="#49C676" />
    <path d="M53 90l76-80 51 50-75 80z" className="logo__lg--light" fill="#B4E9C7" />
    <path d="M129 10l51 50 50-50zM105 140l35-37v74z" className="logo__lg--dark" fill="#49C676" />
    <path d="M128 60l15 15-15 15M120 55l-7 40M105 60L90 75l15 15" />
    <path
      className="lettering"
      id="4"
      d="M288.402 57.577V8.227h12.075v108.85h-12.075V67.902h-23.45v49.175h-12.075V8.227h12.075v49.35h23.45z"
    />
    <path
      className="lettering"
      id="5"
      d="M332.677 8.227h17.85a19.902 19.902 0 015.471.685q6.135 1.757 7.765 8.02a20.368 20.368 0 01.589 5.12v81.2a19.902 19.902 0 01-.685 5.471q-1.758 6.135-8.02 7.764a20.368 20.368 0 01-5.12.59h-17.85a19.902 19.902 0 01-5.471-.686q-6.135-1.757-7.765-8.02a20.368 20.368 0 01-.589-5.119v-81.2a19.902 19.902 0 01.685-5.472q1.758-6.134 8.02-7.764a20.368 20.368 0 015.12-.589zm19.775 93.1v-77.35a18.22 18.22 0 00-.05-1.41q-.18-2.301-1-3.053a2.571 2.571 0 00-.771-.465q-1.214-.497-3.604-.497h-10.85a18.201 18.201 0 00-1.346.046q-2.183.162-3.01.898a1.826 1.826 0 00-.019.018 2.236 2.236 0 00-.508.742q-.518 1.152-.541 3.501a22.539 22.539 0 00-.001.22v77.35a18.22 18.22 0 00.05 1.409q.18 2.301 1 3.053a2.571 2.571 0 00.771.465q1.208.495 3.58.498a22.661 22.661 0 00.024 0h10.85a18.201 18.201 0 001.346-.047q2.183-.162 3.01-.898a1.826 1.826 0 00.019-.018 2.236 2.236 0 00.508-.742q.518-1.152.541-3.501a22.539 22.539 0 00.001-.219z"
    />
    <path
      className="lettering"
      id="6"
      d="M415.802 101.327v-93.1h11.9v95.025a19.902 19.902 0 01-.685 5.471q-1.758 6.135-8.02 7.764a20.368 20.368 0 01-5.12.59h-17.85a19.902 19.902 0 01-5.471-.686q-6.135-1.757-7.765-8.02a20.368 20.368 0 01-.589-5.119V8.227h11.9v93.1a18.22 18.22 0 00.05 1.409q.18 2.301 1 3.053a2.571 2.571 0 00.771.465q1.208.495 3.58.498a22.661 22.661 0 00.024 0h10.85a18.201 18.201 0 001.346-.047q2.183-.162 3.01-.898a1.826 1.826 0 00.019-.018 2.236 2.236 0 00.508-.742q.518-1.152.541-3.501a22.539 22.539 0 00.001-.219z"
    />
    <path
      className="lettering"
      id="7"
      d="M474.952 101.327v-29.75a18.22 18.22 0 00-.05-1.41q-.18-2.301-1-3.053a2.571 2.571 0 00-.771-.465q-1.214-.497-3.604-.497h-11.9a19.902 19.902 0 01-5.471-.686q-6.135-1.757-7.765-8.02a20.368 20.368 0 01-.589-5.119V22.052a19.902 19.902 0 01.685-5.472q1.758-6.134 8.02-7.764a20.368 20.368 0 015.12-.589h15.4a19.902 19.902 0 015.471.685q6.135 1.757 7.765 8.02a20.368 20.368 0 01.589 5.12v16.8h-12.075V23.977a19.869 19.869 0 00-.046-1.41q-.149-2.086-.781-2.899a1.647 1.647 0 00-.135-.154q-.963-.962-4.463-.962h-8.05a19.869 19.869 0 00-1.41.046q-2.086.149-2.899.781a1.647 1.647 0 00-.153.135q-.963.963-.963 4.463v26.425a18.201 18.201 0 00.046 1.345q.163 2.183.899 3.01a1.826 1.826 0 00.018.02 2.236 2.236 0 00.742.507q1.151.519 3.501.541a22.539 22.539 0 00.219.002h11.9a19.902 19.902 0 015.471.685q6.135 1.757 7.765 8.02a20.368 20.368 0 01.589 5.12v33.6a19.902 19.902 0 01-.685 5.471q-1.758 6.135-8.02 7.764a20.368 20.368 0 01-5.12.59h-15.225a19.902 19.902 0 01-5.471-.686q-6.135-1.757-7.765-8.02a20.368 20.368 0 01-.589-5.119v-16.8h12.075v14.875a19.869 19.869 0 00.046 1.409q.149 2.086.781 2.899a1.647 1.647 0 00.136.154q.962.963 4.462.963h7.875a18.201 18.201 0 001.346-.047q2.183-.162 3.01-.898a1.826 1.826 0 00.019-.018 2.236 2.236 0 00.508-.742q.518-1.152.541-3.501a22.539 22.539 0 00.001-.219z"
    />
    <path
      className="lettering"
      id="8"
      d="M515.727 65.627v41.125h29.925v10.325h-42V8.227h40.775v10.325h-28.7v36.75h23.45v10.325h-23.45z"
    />
    <path
      className="lettering-sm"
      id="10"
      d="M592.727 32.202h17.85a19.902 19.902 0 015.471.685q6.135 1.757 7.765 8.02a20.368 20.368 0 01.589 5.12v57.225a19.902 19.902 0 01-.685 5.471q-1.758 6.135-8.02 7.764a20.368 20.368 0 01-5.12.59h-17.85a19.902 19.902 0 01-5.471-.686q-6.135-1.757-7.765-8.02a20.368 20.368 0 01-.589-5.119V46.027a19.902 19.902 0 01.685-5.472q1.758-6.134 8.02-7.764a20.368 20.368 0 015.12-.589zm19.775 69.125V47.952q0-2.423-.557-3.638a2.511 2.511 0 00-.493-.737q-1.05-1.05-4.375-1.05h-10.85q-2.423 0-3.637.557a2.511 2.511 0 00-.738.493q-1.05 1.05-1.05 4.375v53.375a18.22 18.22 0 00.05 1.409q.18 2.301 1 3.053a2.571 2.571 0 00.771.465q1.208.495 3.58.498a22.661 22.661 0 00.024 0h10.85a18.201 18.201 0 001.346-.047q2.183-.162 3.01-.898a1.826 1.826 0 00.019-.018 2.236 2.236 0 00.508-.742q.518-1.152.541-3.501a22.539 22.539 0 00.001-.219z"
    />
    <path
      className="lettering-sm"
      id="11"
      d="M665.002.002v11.2h-6.825a18.201 18.201 0 00-1.346.046q-2.183.162-3.01.898a1.826 1.826 0 00-.019.018 2.236 2.236 0 00-.508.742q-.518 1.152-.541 3.501a22.539 22.539 0 00-.001.22v15.575h12.25v10.325h-12.25v74.55h-12.075v-74.55h-8.4V32.202h8.4V15.827a19.902 19.902 0 01.685-5.472q1.758-6.134 8.02-7.764a20.368 20.368 0 015.12-.589h10.5z"
    />
    <path
      className="lettering"
      id="13"
      d="M742.002 22.052v18.375h-12.075v-16.45a18.22 18.22 0 00-.05-1.41q-.18-2.301-1-3.053a2.571 2.571 0 00-.771-.465q-1.214-.497-3.604-.497h-9.625a19.869 19.869 0 00-1.41.046q-2.086.149-2.899.781a1.647 1.647 0 00-.153.135q-.963.963-.963 4.463v77.525a18.201 18.201 0 00.046 1.345q.163 2.183.899 3.01a1.826 1.826 0 00.018.02 2.236 2.236 0 00.742.507q1.151.519 3.501.541a22.539 22.539 0 00.219.002h9.625q2.423 0 3.638-.558a2.511 2.511 0 00.737-.492q1.05-1.05 1.05-4.375v-16.45h12.075v18.375a19.902 19.902 0 01-.685 5.471q-1.758 6.135-8.02 7.764a20.368 20.368 0 01-5.12.59h-16.975a19.902 19.902 0 01-5.471-.686q-6.135-1.757-7.765-8.02a20.368 20.368 0 01-.589-5.119V22.052a19.902 19.902 0 01.685-5.472q1.758-6.134 8.02-7.764a20.368 20.368 0 015.12-.589h16.975a19.902 19.902 0 015.471.685q6.135 1.757 7.765 8.02a20.368 20.368 0 01.589 5.12z"
    />
    <path
      className="lettering"
      id="14"
      d="M780.852 71.577h-10.325v45.5h-11.9V8.227h31.675a19.902 19.902 0 015.471.685q6.135 1.757 7.765 8.02a20.368 20.368 0 01.589 5.12v35.7q0 11.209-8.831 13.303a17.038 17.038 0 01-2.019.347l16.45 45.675h-12.95l-15.925-45.5zm5.95-53.025h-16.275v42.7h16.275a19.869 19.869 0 001.41-.047q2.086-.149 2.899-.781a1.647 1.647 0 00.154-.135q.962-.962.962-4.462v-31.85a19.869 19.869 0 00-.046-1.41q-.149-2.086-.781-2.899a1.647 1.647 0 00-.135-.154q-.963-.962-4.463-.962z"
    />
    <path
      className="lettering"
      id="15"
      d="M870.102 117.077h-12.075l-4.55-27.65h-22.05l-4.025 27.65h-12.075l16.975-108.85h19.075l18.725 108.85zm-28.35-100.45l-8.925 62.475h19.075l-10.15-62.475z"
    />
    <path
      className="lettering"
      id="16"
      d="M918.052 89.602V8.227h11.025v108.85h-13.125l-22.575-84.35v84.35h-11.025V8.227h14l21.7 81.375z"
    />
    <path
      className="lettering"
      id="17"
      d="M960.402 65.627v41.125h29.925v10.325h-42V8.227h40.775v10.325h-28.7v36.75h23.45v10.325h-23.45z"
    />
  </svg>
);

export default LargeLogo;